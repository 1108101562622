/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-26 14:42:40 
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-01 14:57:41
 */

.badge {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.warningBadge {
  color: $white;
  background-color: $badgeRed;
  width: 50px
}

.successBadge {
  color: $white;
  background-color: $badgeGreen;
  width: 50px
}

.primaryBadge {
  color: $white;
  background-color: $badgeBlue;
}

.defaultBadge {
  color: $white;
  background-color: $badgeYellow;
}
.NoBadge {
  color: $white;
  background-color: $badgeBlack;
}
