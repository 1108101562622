/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-27 17:19:31 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-15 14:29:38
 */
.accordion {
  cursor: pointer;
  margin-bottom: 0.3%;

  .accordionHeader {
    padding: 10px;

    // .headingWrapper {
    //   h5 {
    //     font-size: $defaultH3;
    //     margin: 0px;
    //   }
    // }

    .iconWrapper {
      display: flex;
      width: auto;
      height: auto;
      justify-content: flex-end;
      i {
        font-size: $defaultH4;
      }
    }
  }

  .activeAccordion {
    border-radius: 0px;
    color: $white;
    background-color: $darkBlue;
  }

  .defaultAccordion {
    border-radius: 0px;
    color: $white;
    background-color: $darkBlue;
  }
}

.secondaryAccordion {
  .accordionHeader {
    padding-top: 4px;
    padding-bottom: 2px;
    padding-left: 20px;
    padding-right: 2px;
  }

  .activeAccordion {
    border-radius: 0px;
    color: $white;
    background-color: $lightBlue;
  }

  .defaultAccordion {
    border-radius: 0px;
    color: $white;
    background-color: $lightBlue;
  }

  h5 {
    font-size: 16px;
  }
}
