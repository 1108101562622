/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2021-01-18 15:18:27 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-11 10:33:33
 */
.nav-dropdown {
  color: white;
  font-weight: bold;
  background-color: red;
}

.erlWebMainWrapper {
  @extend .fullWidthDiv;

  .profile-btn {
    flex: 1;
  }

  .profile-div {
    // width: 260px;
    background-color: $menuHeaderBarBGColor;
    font-size: $menuListItemText;

    flex: 1;
    justify-content: flex-end;

    .menuWrapper {
      margin-top: 0px;
    }

  }

  .logoWrapperBtn {
    display: table-cell;
    justify-content: center;
    vertical-align: middle;

    // width: fit-content;
    img {
      height: 100px;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
      // width: fit-content;
    }
  }

  .successMessage {
    background-color: #acdf87;
    width: 100%;
    font-size: large;
    color: #1e5631;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .errorMessage {
    background-color: #f5b0b0;
    width: 100%;
    font-size: large;
    color: #e60e0e;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .profile-div-in {
    width: 50px;
    background-color: $menuHeaderBarBGColor;
    font-size: $menuListItemText;
    align-items: center;
    justify-content: center;
    color: $white;
    height: 50px;
  }

  .profile-div-out {
    margin-top: 20px;
    // width: 195px;
    background-color: $menuHeaderBarBGColor;
    font-size: $menuListItemText;
    align-items: center;
    color: $white;
    height: 50px;
  }
.profile-header{
  margin-left: -20px;
  margin-top: -5px;
}
  .profile {
    color: $white;
  }

  .toggle-div {
    background-color: $menuHeaderBarBGColor;
    border-color: $menuHeaderBarBGColor;
    align-items: center;
    display: inline;
    color: $white;
    font-size: 14px;
    // opacity: 0;
    // font-weight: 500;
    padding-top: 20px !important;
    cursor: pointer;
  }

  .toggle-div:hover {
    color: $white;
    background-color: $menuHeaderBarBGColor;
    border-color: $menuHeaderBarBGColor;
    // opacity: 0;
  }

  .active {
    font-weight: bold;
    border-bottom: 2px solid $white;
    color: $white;
    // border-color: $menuHeaderBarBGColor;
    background-color: $menuHeaderBarBGColor;
    // opacity: 0;
  }

  .image-stack {
    position: relative;
    width: 100%;
  }

  .container {
    padding: 100px 30px;
    width: 100%;
    margin: 0 auto;
    max-width: 800px;
  }

  .image-stack__item--top {
    padding-top: 2%;
    padding-right: 2%; // arbitrary value
    padding-left: 70%; // arbitrary value
    padding-bottom: 15%;
    width: 100%;
    height: 100%; // arbitrary value
    position: relative;
  }

  .image-stack__item--bottom {
    position: absolute;
    top: 80px;
    right: 0;
    right: 0;
    top: 0;
    width: 100%; // this is an arbitrary width, depends on the design
    // z-index: -1;
  }

  .erlContent {
    max-width: $erlContentMaxWidth;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    align-items: center;

    .quickLinkWrapper {
      ul {
        margin-left: 20px;

        li {
          a {
            color: $white;
          }
        }
      }
    }
  }

  .loginBtn {
    background-color: $menuHeaderBarBGColor;
    color: $white;
    height: auto;
    width: 100px;

    font-size: $defaultH7;
    padding: 10px;
    border-radius: 4px;
    border: 2px solid $white;
  }

  .profileBtn {
    background-color: $menuHeaderBarBGColor;
    color: $white;
    height: auto;
    width: 100px;
    padding: 10px;
  }

  .erlWebHeaderMainWrapper {
    @extend .fullWidthDiv;

    .topHeaderWrapper {
      @extend .fullWidthDiv;
      background-color: $topHeaderBarBGColor;
      font-size: $topheaderBarText !important;
      color: $white !important;
      padding: 2px 0px;
      justify-content: space-between;

      .topBarContactWrapper {
        display: flex;
        flex-direction: row;

        div {
          // margin-right: 15px;

          i {
            margin-right: 5px;
            margin-bottom: -1px;
          }
        }
      }

      .topBarLanguageWrapper {
        display: flex;
        flex-direction: row;
        justify-content: end;
        -webkit-justify-content: flex-end;
        float: right;

        span {
          padding: 2px 10px;
          font-size: $topBarLanguageText !important;
        }
      }
    }

    .menuHeaderWrapper {
      @extend .fullWidthDiv;
      background-color: $menuHeaderBarBGColor;
      padding: 30px 0px;
      box-shadow: 0 4px 10px -2px #ccc;

      .row {
        display: table !important;
        width: 100%;
        height: 80px;
        margin-left: 0px !important;
        margin-right: 0px !important;
        // margin-top: 30px !important;
        vertical-align: middle;
        // padding-top: 20px;
      }

      .logoWrapper {
        display: table-cell;
        justify-content: center;
        vertical-align: middle;
        @media screen and (max-width: $smallDevice) {
          justify-content: left;      
        }
        // width: fit-content;
        img {
          height: 100px;
          padding-top: 20px;
          padding-left: 20px;
          padding-bottom: 20px;
          @media screen and (max-width: $smallDevice) {
            padding-left: 0;        
          }
          // width: fit-content;
        }
      }

      a {
        font-size: 12px !important;
      }

      .navbar {
        display: table-cell !important;
        width: inherit !important;
        vertical-align: top;
        align-items: center !important;
        // padding-top: 20px;
      }

      .linkText {
        color: $white;
      }

      .menuWrapper {
        align-items: center;
        margin-top: 12px;
        height: 80%;

        ul {
          list-style-type: none;
          margin: 0;

          // padding: 0;
          // padding-top: 20px;
          a {
            padding-left: 5px;
            float: left;
            // height: 40px;
          }
        }

        li {
          display: inline;
          color: $white;
          font-size: $menuListItemText;
          font-weight: 500;
          // padding: 10px !important;
          cursor: pointer;

          // .loginBtn {
          //   background-color: $menuHeaderBarBGColor;
          //   color: $white;
          //   height: auto;
          //   width: 100px;

          //   font-size: $defaultH7;
          //   padding: 10px;
          //   border-radius: 4px;
          //   border: 2px solid $white;
          // }

          .loginBtn-new {
            background-color: $menuHeaderBarBGColor;
            color: $white;
            height: auto;

            font-size: $defaultH7;
            padding: 10px;
            border-radius: 4px;
            border: 2px solid $white;
          }
        }

        // li:hover {
        //   // border-bottom: 2px solid $white;
        //   color: $white;
        //   background-color: $topHeaderBarBGColor;
        //   padding: 5px;
        //   font-weight: bold;
        // }

        // .loginBtnLi:hover {
        //   border-bottom: 0px !important;
        // }

        // .active {
        //   font-weight: bold;
        //   // border-bottom: 2px solid $white;
        //   color: $white;
        //   border-color: $menuHeaderBarBGColor;
        //   //  background-color: $menuHeaderBarBGColor;

        //   display: flex;
        //   // height: 100%;
        //   padding: 5px;
        //   background-color: $topHeaderBarBGColor;
        // }
      }

      .active {
        font-weight: bold;
        // border-bottom: 2px solid $white;
        color: $white;
        border-color: $menuHeaderBarBGColor;
        //  background-color: $menuHeaderBarBGColor;

        // display: flex;
        // height: 100%;
        // padding: 5px;
        background-color: $topHeaderBarBGColor;
      }
    }
  }

  .erlWebBodyMainWrapper {
    @extend .fullWidthDiv;

    .uiCardWrapper {
      max-width: auto;
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 5%;
      margin-bottom: 5%;

      .card {
        // border: 1px solid $lightBlue;
        box-shadow: 0px 0px 10px 2px #ccc;
      }
    }

    .uiCardWrapper-new {
      max-width: auto;
      margin-left: 14.5%;
      margin-right: 14.5%;
      margin-top: 5%;
      margin-bottom: 5%;

      .card {
        // border: 1px solid $lightBlue;
        box-shadow: 0px 0px 10px 2px #ccc;
      }
    }

    .uiCardWrapperFaq {
      max-width: auto;

      .card {
        // border: 1px solid $lightBlue;
        box-shadow: 0px 0px 4px 2px #ccc;
      }
    }
  }

  .erlWebFooterMainWrapper {
    @extend .fullWidthDiv;
    background-color: $darkBlueII;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    color: $white;

    h5 {
      font-size: footerH5;
      margin-bottom: 20px;
    }

    ul {
      padding: 0px !important;

      li {
        list-style-type: none;
        margin-bottom: 10px;
      }
    }

    .alignMe b {
      display: inline-block;
      width: 40%;
      position: relative;
      padding-left: 30px;
      padding-right: 10px;
      /* Ensures colon does not overlay the text */
    }

    .alignMe b::after {
      content: ":";
      position: absolute;
      right: 10px;
    }
  }

  .erlWebHeaderMainWrapperTextItem {
    font-weight: bold;
    color: $white;

    h5 {
      font-size: footerH5;
      // margin-bottom: 20px;
    }
  }

  .erlWebHeaderMainWrapperText {
    @extend .fullWidthDiv;
     background-color: $darkBlue;
    // padding-top: 30px;
    // padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 30px;
    color: $white;

    h5 {
      font-size: footerH5;
      // margin-bottom: 20px;
    }

    ul {
      padding: 0px !important;

      li {
        list-style-type: none;
        // margin-bottom: 10px;
      }
    }

    hr {
      background-color: $white;
    }
  }
  .erlWebHeaderMainWrapperText:hover{
     background-color:$lightBlue;
     color: $white;
  }
}

.checkStatusDropdown {
  width: 58% !important;
  margin-left: 0%;
  margin-right: 0.5% !important;
  font-size: 13px;
  // background-color: #ACDF87;
}

.checkStatusInputbox {
  margin-left: 1%;
  width: 37%;
  margin-right: 0.25%;
  margin-bottom: 1%;
}

.ChechStausButtons {
  margin-left: 0.5%;
}