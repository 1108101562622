.input-group-append {
  .input-group-text {
    background-color: $white;
    border-left: 0px;
  }
}

.input-group-prepend {
  .input-group-text {
    background-color: $defaultSecondary;
  }
}

.input-box-with-icon {
  border-right: 0px;
  border-radius: 5px;
}

.input-box-outside-icon {
  margin-left: 5px;
  margin-top: 4px;
  @media screen and (max-width: $mediumDevice) {
    display: none;
  }
}