/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-26 11:03:37 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-02-08 16:52:53
 */

/** card title **/
.titleAlignLeft {
  text-align: left;
}

.titleAlignCenter {
  text-align: center;
}

.titleAlignRight {
  text-align: right;
}
/** card title **/

/** card placement**/
.cardAlignCenter {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/** card placement**/

/** card size **/
.cardSizeExtraSmall {
  width: 25%;
}

.cardSizeSmall {
  width: 50%;
  padding-top: 1%;
  @media screen and (min-width: $smallDevice) {
    padding-left: 3%;
    padding-right: 3%;
  }
  @media screen and (min-width: $mediumDevice) {
    padding-left: 4%;
    padding-right: 4%;
  }
  @media screen and (min-width: $largeDevice) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.cardSizeMedium {
  width: 75%;
  padding-top: 2%;
  @media screen and (min-width: $smallDevice) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media screen and (min-width: $mediumDevice) {
    padding-left: 7%;
    padding-right: 7%;
  }
  @media screen and (min-width: $largeDevice) {
    padding-left: 10%;
    padding-right: 10%;
  }
}

.cardSizeLarge {
  width: 100%;
  padding-top: 3%;
  @media screen and (min-width: $smallDevice) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media screen and (min-width: $mediumDevice) {
    padding-left: 10%;
    padding-right: 10%;
  }
  @media screen and (min-width: $largeDevice) {
    padding-left: 15%;
    padding-right: 15%;
  }
  // padding-bottom: 3%;
}
/** card size **/

.borderLess {
  margin: 0%;
  border-radius: 0px;
  border: 0px;
}

.marginLeft {
  margin-left: 20px;
}

.marginRight {
  margin-right: 20px;
}

.marginTop {
  margin-top: 20px;
}

.marginBottom {
  margin-bottom: 20px;
}

.paddingBottom {
  padding-bottom: 20px;
}

.paddingTop {
  padding-top: 20px;
}

.paddingLeft {
  padding-left: 20px;
}

.paddingRight {
  padding-right: 20px;
}

.paddingRightExtend {
  padding-right: 30px;
}

.overflowX {
  overflow-x: auto;
}

.overflowY {
  overflow-y: auto;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}
