/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2021-01-18 15:25:37 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-11 14:32:18
 */

// Element Colors
$black: black;
$white: #ffff;
$lightBlue: #0c5bdc;
$darkBlue: #01237d;
$lightBlueII: #edf4fc;
$darkBlueII: #111a3b;
$grey: grey;

// button colors
$googleRed: #df4930;
$facebookBlue: #507bbf;
$emailBlue: #132748;
$disabledPrimary: #88aeed;
$disabledOutline: #8eb0f0;

// badge colors
$badgeGreen: #026e18;
$badgeRed: #a52b16;
$badgeBlue: #1453c4;
$badgeYellow: #be950c;
$badgeBlack:#000000;

// accordion colors
$activePrimary: #0c59db;
$defaultPrimary: #01237d;
$activeSecondary: #5787d9;
$defaultSecondary: #aeb3b9;

$topHeaderBarBGColor: $lightBlue;
$menuHeaderBarBGColor: $darkBlue;

$loaderBackgroundColor: rgba(200, 200, 200, 0.5);

// auth
$borderColor: #8080807d;
/*
* ----------------------------
* Media Queries Screen Sizes and width height
* ----------------------------
*/
$extralargeDevice: 1800px;
//Desktop
$largeDevice: 1200px;
//Tab
$mediumDevice: 992px;
//Mobile
$smallDevice: 768px;
$extraSmallDevice: 576px;
$extraSmallDevice2: 500px;

$mobileBreakPoint: $largeDevice;

/*
* ----------------------------
* Media Queries Screen Sizes width height
* ----------------------------
*/

/*
* ----------------------------
* Padding Values
* ----------------------------
*/

// Common values
$defaultPadding: 15px;
$defaultHalfPadding: $defaultPadding / 2;

/*
* ----------------------------
* Padding Values
* ----------------------------
*/

/*
* ----------------------------
* Margin Values
* ----------------------------
*/

// Common values
$defaultMargin: 15px;
$defaultHalfMargin: $defaultMargin / 2;

/*
* ----------------------------
* Margin Values
* ----------------------------
*/

/*
* ----------------------------
* Element Font Sizes
* ----------------------------
*/

// Common sizes
$defaultH1: 32px;
$defaultH2: 25px;
$defaultH3: 18px;
$defaultH4: 16px;
$defaultH5: 13px;
$defaultH6: 14px;
$defaultH7: 12px;

$defaultFontSize: $defaultH6;
$topheaderBarText: $defaultH7;
/*
* ----------------------------
* Element Font Sizes
* ----------------------------
*/

$topBarLanguageText: 10px;
$erlContentMaxWidth: 1020px;

$logoHeight: 60px;

//menu
$menuListItemText: 13px;

//footer
$footerH5: 20px;

// home page
$homePageSliderHeight: 520px;
$introCardHeight: 250px;
// $introCardHeight: 180px;

//icon
$iconHeight: 55px;
$iconWidth: 55px;
$iconText: 30px;

// erl Intro
$erlIntroText: 25px;

//erlProcessFlow
$processFlowText: 25px;

//button
$buttonMinWidth: 225px;

//box shadow
$boxShadow1: rgba(0, 0, 0, 0.2);
$boxShadow2: rgba(0, 0, 0, 0.19);

// popup
$popupBoxBackground: #00000050;

//z-index
