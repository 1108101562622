/*
 * @Author: Binara Medawatta 
 * @Date: 2021-02-11 18:11:18 
 * @Last Modified by:   Binara Medawatta 
 * @Last Modified time: 2021-02-11 18:11:18 
 */
.loaderBox {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;

  .circleLoader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $loaderBackgroundColor;

    .spinner {
      color: $darkBlue;
    }
  }
}
