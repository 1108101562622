/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2021-01-18 15:25:08 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-11 14:32:19
 */

 .initclass {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  border: none;
  font-weight: normal;
  text-shadow: none;
  background-color: none;
  box-shadow: none;
}

.defaultFontFamily {
  font-family: "Mada", sans-serif !important;
}

.defaultFontSize {
  font-size: $defaultFontSize !important;
}

body {
  @extend .initclass;
  @extend .defaultFontFamily;
  @extend .defaultFontSize;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
font,
span,
div,
a,
small,
table {
  @extend .initclass;
  @extend .defaultFontFamily;
}

.textFloat{
  margin-left: auto; 
  margin-right: 0;
}

a {
  text-decoration: none !important;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.floatNone {
  float: none !important;
  clear: both !important;
}

.displayNone {
  display: none !important;
}

.displayBlock {
  display: block !important;
}

.clearBoth {
  clear: both;
}

.fullWidthDiv {
  @extend .floatLeft;
  width: 100%;
}

.halfWidthDiv {
  @extend .floatLeft;
  width: 50%;
}


.mainTxtHeading{
  font-size: $defaultH2;
  color: $black;
  margin-bottom: 15px;
}