/*
 * @Author: Jayani Nawarathna 
 * @Date: 2021-02-01 17:04:32 
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-02 18:47:20
 */
.privacyPolicyPageWrapper {
  .erlIntroduction {
    h2 {
      margin-bottom: 15px;
    }
    h5 {
      margin-top: 15px;
    }
    h6 {
      margin-top: 15px;
    }
    ul {
      list-style-type: none;
    }
    p {
      text-align: justify;
    }
  }
}
