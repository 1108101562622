/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Description: SCSS files initializer  
 * @Date: 2020-02-06 18:30:48 
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-26 11:05:49
 */

/* Include library files */
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
// @import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@mdi/font/scss/materialdesignicons.scss';


/* Include base files */
@import './base/variables';
@import './base/function';
@import './base/mixins';
@import './base/base';

/**Include Modules **/
@import './layouts/modules/index';

/** Include Template **/
@import './layouts/ui-components/templates/template/template';

/** Include UI Elements **/
@import './layouts/ui-components/ui-elements/index';

@import "~bootstrap/scss/bootstrap";