/*
 * @Author: Jayani Nawarathna 
 * @Date: 2021-02-01 16:29:56 
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-02 18:28:03
 */
.termsAndConditionsPageWrapper {
  .erlIntroduction {
    h2 {
      margin-bottom: 15px;
    }
    h5 {
        margin-top: 15px;
    }
    ul {
      list-style-type: none;
    }
  }
}
