.revenuePageWrapper {
  .seperateBox {
    margin: 15px 20px;

  }

  .btnWrapper {
    margin: 15px 20px;
    margin-left: 20%;

    .btn2 {
      margin-left: 15px;
    }
  }
}

.payment-info {
  .form-check {

    margin-right: 40%;
  }

  // @media screen and (min-width: $largeDevice) {
  //   margin-right: 37%;
  // }

  @media screen and (min-width: $extralargeDevice) {
    .form-check {

      margin-right: 37%;
    }
  }
}