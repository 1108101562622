/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-26 13:20:57 
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-05 17:52:38
 */

.btnWrapper {
  padding: 30px 0px 10px 0px;
  display: flex;
  justify-content: center;

  @extend .button;
}

.button {
  button {
    border: 2px solid $topHeaderBarBGColor;
    padding: 5px 20px;
    min-width: 110px;
    border-radius: 5px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.6);
  }

  .btnPrimary {
    color: $white;
    background-color: $topHeaderBarBGColor;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.6);
  }

  .btnSecondary {
    color: $black;
    background-color: $defaultSecondary;
    border-color: $defaultSecondary;
  }

  .disabledPrimary {
    background-color: $disabledPrimary;
    border-color: $disabledPrimary;
    color: $white;
  }

  .btnOutlinePrimary {
    color: $topHeaderBarBGColor;
    background-color: $white;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.6);
  }

  .disabledOutline {
    border-color: $disabledOutline;
  }

  .googleSignin {
    color: $white;
    border: 2px solid $googleRed;
    background-color: $googleRed;
    width: 200px;
  }

  .facebookSignin {
    color: $white;
    border: 2px solid $facebookBlue;
    background-color: $facebookBlue;
    width: 200px;
  }

  .emailSignin {
    color: $white;
    border: 2px solid $emailBlue;
    background-color: $emailBlue;
    width: 200px;
  }

  @media screen and (max-width: 290px) {

    button {
     
      padding: 5px 2px;
      min-width: 85px;
     
    }
  
  }
}


.roundedIconButton {
  border: grey solid 1px;
  // border-color: $grey;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 23px;
  border-radius: 30px;
}


