/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-29 12:32:33 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-19 13:31:33
 */

.popupBox {
  position: fixed;
  background: $popupBoxBackground;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;

  @keyframes example {
    from {
      bottom: 0px;
    }
    to {
      bottom: 75vh;
    }
  }

  .box {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    margin: 0 auto;
    height: auto;

    animation-name: example;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    @media screen and (max-width: $extraSmallDevice2) {
      width: 80%;
    }
    @media screen and (min-width: $extraSmallDevice) {
      width: 80%;
    }
    @media screen and (min-width: $smallDevice) {
      width: 80%;
    }
    @media screen and (min-width: $mediumDevice) {
      width: 70%;
    }
    @media screen and (min-width: $largeDevice) {
      width: 55%;
    }
    max-height: 70vh;
    margin-top: 100vh;
    background: $white;
    overflow-x: hidden;
    overflow-y: auto;

    .popup {
      .popupHeader {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        margin-top: auto;
        margin-bottom: auto;
        padding: 10px 15px;

        color: $white;
        background-color: $activePrimary;

        .popupTitle {
          font-size: 20px;
          color: $white;
        }

        .popupCloseButton {
          float: right;
          border: none;
          background-color: initial;
          color: $white;
          font-size: 20px;
        }
      }

      .popupContent {
        margin-top: 3%;
        margin-bottom: 5%;
        margin-left: 5%;
        margin-right: 5%;
        .badgeAlign {
          .badge {
            @media screen and (max-width: $extraSmallDevice2) {
              float: left !important;
            }
            @media screen and (max-width: $extraSmallDevice) {
              float: left !important;
            }
            @media screen and (max-width: $smallDevice) {
              float: left !important;
            }
          }
        }
      }
    }
  }
}
.menuPopupBox {
  position: relative;
  z-index: 1;

  a {
    padding-left: 0px !important;
    float: none !important;
    height: unset !important;
  }
  img {
    border: $white solid 3px;
    border-radius: 10px;

    @media screen and (max-width: $extraSmallDevice2) {
      width: 20%;
    }
    @media screen and (max-width: $extraSmallDevice) {
      width: 20%;
    }
    @media screen and (min-width: $smallDevice) {
      width: 100%;
    }
    @media screen and (min-width: $mediumDevice) {
      width: 100%;
    }
    @media screen and (min-width: $largeDevice) {
      width: 100%;
    }
  }
  .paddingNone {
    padding: none;
  }
  .imagePadding {
    padding: 10px;
  }
  .box {
    position: absolute;
    top: 0%;
    right: 0%;
    height: auto;
    width: 250px;
    margin-top: 1em;
    background: $white;
    overflow-x: hidden;
    overflow-y: hidden;
    box-shadow: 0px 1px 1px 0px $grey;

    .popup {
      margin: 0px;
      .menuPopupHeader {
        color: $white;
        background-color: $activePrimary;
        border-radius: unset;

        .menuHeaderBody {
          padding: 10px;
        }
      }

      .popupContent {
        line-height: normal;
        li {
          background: none;
        }
        .card {
          border: none;
          background: none;
          .card-body {
            padding: 0px 0px 0px 10px;
          }
        }
        .subMenuItem {
          padding-bottom: 15px;
        }
        .subMenuItem:hover {
          background-color: $darkBlue;
        }
        a:hover {
          color: $white !important;
        }
      }
    }
  }
}
