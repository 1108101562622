/*
 * @Author: Jayani Nawarathna 
 * @Date: 2021-02-03 09:45:20 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-02-08 23:11:27
 */
.authFormWrapper {
  .columnBoder {    
    @media screen and (max-width: $extraSmallDevice2) {
      border-bottom: solid $borderColor 2px;
      padding-bottom: 10px;
    }
    @media screen and (max-width: $extraSmallDevice) {
      border-bottom: solid $borderColor 2px;
      padding-bottom: 10px;
    }
    @media screen and (min-width: $smallDevice) {
      border-right: solid $borderColor 2px;
    }
    @media screen and (min-width: $mediumDevice) {
      border-right: solid $borderColor 2px;
    }
    @media screen and (min-width: $largeDevice) {
      border-right: solid $borderColor 2px;
    }
    .textRight {
      text-align: right;
    }
  }
  .contentCenter {
    margin-left: auto;
    margin-right: auto;
    .marginTopContent {
      button {
        margin-top: 15% !important;
      }
      p {
        margin-top: 10% !important;
      }
    }
    .textCenter {
      text-align: center !important;

      button {
        margin-top: 5%;
      }
      p {
        margin-top: 5%;
      }
    }
    .textRight {
      text-align: right;
    }
  }
}
