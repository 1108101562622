/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2021-01-18 15:18:27 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-29 13:54:48
 */

.alignCenterWrapper{
  display: flex;
  align-items: center;
}

 .introCardWrapperNews {
  padding: 5% 5% 0% 5%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 80%;
  max-width: 100%;
  background-color: $darkBlue;
  color: $white;
  box-shadow: 0 4px 10px 4px $boxShadow1, 0 6px 20px 0 $boxShadow2;
  opacity: 0.8;
}

.slider-item-div {
  padding: 5% 5% 15% 5%;
  // background-color: white;
  text-align: center;
  height: 100%;
  width: 100%;
}

.carousel-style {
  height: 100% !important;
}
.homePageWrapper {
  .sliderWrapper {
    width: 100%;
    height: $homePageSliderHeight;
    background-image: url("../../../img/home.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .erlMainIntroWrapper {
    display: flex;
    flex-direction: row;
    // margin-top: -65px;
    margin-top: 1px;

    .marginNone {
      margin-left: unset;
      margin-right: unset;
    }

    .introCardWrapper {
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      height: $introCardHeight;
      width: auto;
      // align-content: center;
      align-items: center;
      justify-content: center;
      box-shadow: 0 4px 10px 4px $boxShadow1, 0 6px 20px 0 $boxShadow2;

      .iconWrapper {
        align-self: center;
        width: $iconWidth;
        height: $iconHeight;
        border-radius: 60%;
        display: flex;
        justify-content: center;
        justify-items: center;
        margin-bottom: 20px;
        i {
          font-size: $iconText;
          align-self: center;
        }
      }

      h3 {
        text-align: center;
        width: 100%;
        margin-bottom: 15px;
        font-size: $defaultH3;
        color: $black;
      }
      h6 {
        text-align: center;
        width: 100%;
        font-size: $defaultH6;
        // font-weight: 550;
        font-family: 'Times New Roman';
      }
    }

    .introCardWrapper:hover {
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.8);
    }

    .whiteBG {
      background-color: $white;

      .iconWrapper {
        background-color: $lightBlueII;
        color: $topHeaderBarBGColor;
      }

      h3 {
        color: $black;
      }

      h6 {
        color: $black;
      }
    }

    .lightBlueBG {
      background-color: $lightBlue;

      .iconWrapper {
        background-color: $white;
        color: $topHeaderBarBGColor;
      }

      h3 {
        color: $white;
      }

      h6 {
        color: $white;
      }
    }
  }

  .erlIntroduction {
    padding: 20px 20px;
    h1 {
      font-size: $erlIntroText;
      color: $black;
      margin-bottom: 5px;
    }
  }

  .erlProcessFlowWrapper {
    background-color: $lightBlueII;
    padding: 20px 20px;

    h1 {
      font-size: $processFlowText;
      color: $black;
      margin-bottom: 5px;
    }

    // p {
    //   // margin-bottom: 2.5px;
    // }
  }
}
