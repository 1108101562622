.rightAlignLabel {
  @media screen and (max-width: $mediumDevice) {
    float: left;
  }
  @media screen and (min-width: $mediumDevice) {
    float: right;
  }
}

.label-outside-icon {
  margin-left: 5px;
  @media screen and (min-width: $mediumDevice) {
    display: none;
  }
}
